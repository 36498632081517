<template>
  <!-- <div :style="f_styleLoginBackground()"> -->
  <div :style="f_calculateLoginGeneralStyle()">
    <b-container fluid>
      <b-row align-v="center" align-h="center" style="height: 100vh">
        <b-col sm="10" md="8" lg="6" align-v="center">
          <b-card align="center" align-v="center" id="at" :style="f_styleLoginCardOut()">
            <div>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <h4 :style="f_calculateLoginApplicationNameStyle()">
                    <span v-if="StoreFrontendSettings.data.application_name && StoreFrontendSettings.data.application_name[StoreLang]" @click="f_goToMainPage()" style="cursor: pointer;"> {{ StoreFrontendSettings.data.application_name[StoreLang] }}</span>
                  </h4>
                </b-col>
                <b-col cols="2">
                  <b-dropdown variant="white" style="padding: 0px;" no-caret>
                    <template slot="button-content">
                      <span style="color: whitesmoke; font-size: 15px;">
                        {{ d_selectedLang }}
                      </span>
                    </template>
                    <template v-for="(lang, lang_index) in StoreLangList.data">
                      <b-dropdown-item @click="f_selectLang(lang)">
                        {{ lang.label }}
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                  <template v-if="d_showLoginButtonClicked">
                    <!-- <img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 20px; height: 20px;" /> -->
                  </template>
                </b-col>
              </b-row>
            </div>
            <b-card v-if="!d_showPasswordReset" :style="f_styleLoginCardIn()">
              <!-- Uygulamaların başka bir uygulamaya girerken hangi uygulamaya gireceğinin adını göstermektedir. -->
              <h5 v-if="d_userExternalapplicationData" style="color: black;">
                <template v-if="d_userExternalapplicationData.data && d_userExternalapplicationData.data['0'] && d_userExternalapplicationData.data['0']['2'] && d_userExternalapplicationData.data['0']['2']['lang'] && d_userExternalapplicationData.data['0']['2']['lang'][StoreLang]">
                  <a style="color: rgb(1, 32, 39, 1);" :href="d_userExternalapplicationData.data['0']['2']['lang'][StoreLang]"> <i class="fa fa-sign-in"></i> {{ d_userExternalapplicationData.data['0']['2']['lang'][StoreLang] }}</a>
                </template>
                <template v-else-if="d_userExternalapplicationData.data && d_userExternalapplicationData.data['0'] && d_userExternalapplicationData.data['0']['2'] && d_userExternalapplicationData.data['0']['2']['val']">
                  <a style="color: rgb(1, 32, 39, 1);" :href="d_userExternalapplicationData.data['0']['2']['lang'][StoreLang]"><i class="fa fa-sign-in"></i> {{ d_userExternalapplicationData.data['0']['2']['val'] }}</a>
                </template>
              </h5>
              <h5 style="color: black;">{{ StoreLangTranslation.data['login_screen'][StoreLang] }}</h5>
              <b-form v-if="!d_accessKeyToken && StoreFrontendSettings.data.login_function_external !== '1'" @submit.prevent="f_loginStart">
                <b-row>
                  <b-col cols="12">
                    <b-form-group validated label-for="normalEmail">
                      <b-form-input :autofocus="p_viewMode === 'only_show' ? false : true" id="normalEmail" type="text" :placeholder="StoreLangTranslation.data['enter_username'][StoreLang]" required autocomplete="email" :style="f_styleLoginInput()" v-model="credentials.username" :formatter="toLowerCase"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group validated label-for="normalPass">
                      <b-form-input id="normalPass" type="password" :placeholder="StoreLangTranslation.data['enter_password'][StoreLang]" required autocomplete="current-password" :style="f_styleLoginInput()" v-model="credentials.password"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div id="captcha"></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button size="sm" @click="f_createCaptcha()" variant="white"><i class="fa fa-edit" style="color: #45dc45;"></i>&nbsp; {{ StoreLangTranslation.data['change'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group validated label-for="normalCaptcha">
                      <b-form-input id="captchaCode" type="text" :placeholder="StoreLangTranslation.data['write_captcha_code'][StoreLang]" required v-model="d_captchaWrittenCode"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="d_usernamePasswordError" style="color: red;">
                  <b-col cols="12">
                    {{  StoreLangTranslation.data['username_or_password_error'][StoreLang] }}
                  </b-col>
                </b-row>
                <div slot="footer">
                  <template v-if="!d_showLoginButtonClicked">
                    <b-button type="reset" size="sm" :style="f_styleLoginButton()"><i class="fa fa-ban" style="color: #f87574;"></i>&nbsp;{{ StoreLangTranslation.data['clear'][StoreLang] }}
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="sm" :style="f_styleLoginButton()"><i class="fa fa-ban" style="color: #f87574;"></i>&nbsp; {{ StoreLangTranslation.data['process'][StoreLang] }}
                    </b-button>
                  </template>
                  <template v-if="!d_showLoginButtonClicked">
                    <b-button type="submit" size="sm" :style="f_styleLoginButton()"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp;{{ StoreLangTranslation.data['login'][StoreLang] }}
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="sm" :style="f_styleLoginLoading()"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp; {{ StoreLangTranslation.data['loading'][StoreLang] }}
                    </b-button>
                  </template>
                  <br>
                  <!-- <b-button :disabled="d_showLoginButtonClicked" @click="f_goToResetPassword()" size="sm" style="background-color: rgba(170, 70, 12, 1);width: 200px;color: #c1e4e1;"><i class="fa fa-question" style="color: #f87574;"></i>&nbsp;şifremi unuttum
                  </b-button> -->
                </div>
              </b-form>
            </b-card>
            <b-row v-if="!d_showPasswordReset && !d_accessKeyToken && StoreFrontendSettings.data.login_function_external !== '1'">
              <b-col cols="12">
                <span :style="f_calculatePasswordResetStyle()" @click="f_passwordReset()">
                  {{ StoreLangTranslation.data['password_reset'][StoreLang] }}
                </span>
              </b-col>
            </b-row>
            <b-row v-if="d_showLoginButtonClicked" style="color: white;">
              <b-col cols="12">
                <span style="cursor: pointer;">
                  {{ StoreLangTranslation.data['login_is_continueing_please_wait'][StoreLang] }}
                </span>
              </b-col>
            </b-row>
            <b-card v-if="d_showPasswordReset" style="margin-top: 0px; background-color: rgba(255, 255, 255, 0.7); border-radius: 15px;">
              <h5 style="color: black;">
                <b-row>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left; text-align: center;">
                    {{ StoreLangTranslation.data['register'][StoreLang] }}
                  </b-col>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                </b-row>                
              </h5>
              <b-row style="padding: 3px;">
                <b-col cols="12" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  <b-form-input type="text" class="form-control-warning" id="mail" key="mail" :formatter="f_toLowerCase(d_resetData.mail, 'mail')" v-model="d_resetData.mail" :state="f_mailRegexCheck(d_resetData.mail)" :placeholder="StoreLangTranslation.data['please_write_mail'][StoreLang]"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    {{ StoreLangTranslation.data['please_write_true_mail'][StoreLang] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row v-if="f_mailRegexCheck(d_resetData.mail)" style="padding: 10px;">
                <b-col cols="5"></b-col>
                <b-col cols="7" style="text-align: right;">
                  <template v-if="!d_resetMailButtonClicked">
                    <b-button variant="white" @click="f_sendResetMail()">
                      <i class="fa fa-send-o"></i>
                       {{ StoreLangTranslation.data['send_reset_mail'][StoreLang] }} 
                    </b-button>
                  </template>
                  <template v-else>
                       {{ StoreLangTranslation.data['process_continuing_please_wait'][StoreLang] }} 
                  </template>
                </b-col>
              </b-row>
            </b-card>
            <div v-if="StoreFrontendSettings.data.login_function_external === '1'" style="padding: 10px;">
              <span :style="f_calculateWaccountLoginStyle()" @click="f_waccountLogin()">{{ StoreLangTranslation.data['waccount_login'][StoreLang] }}</span>
            </div>
            <div style="padding: 10px;" v-if="d_showPasswordReset">
              <span :style="f_calculatePasswordResetStyle()" @click="f_passwordReset()">{{ StoreLangTranslation.data['login'][StoreLang] }}</span>
            </div>
            <div style="padding: 10px;" v-if="StoreFrontendSettings.data.register_function === '1'">
              <span @click="f_goToRegisterPage()" :style="f_calculateRegisterButtonStyle()">{{ StoreLangTranslation.data['register'][StoreLang] }}</span>
            </div>
            <div style="padding: 10px;">
              <span @click="f_goToMainPage()" :style="f_calculateGotoDashboardStyle()">{{ StoreLangTranslation.data['go_to_dashboard'][StoreLang] }}</span>
            </div>
            <div style="padding: 10px;" v-if="StoreFrontendSettings.data.login_slogan_text && StoreFrontendSettings.data.login_slogan_text[StoreLang]">
              <small :style="f_calculateSloganTextStyle()">
              {{ StoreFrontendSettings.data.login_slogan_text[StoreLang] }}
            </small>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import UserExternalapplicationService from '@/services/externalapplication';
import LoginService from '@/services/login';
import { WisdomRegex } from '@/public/WisdomRegex';
import { data as lang_list } from '@/options/lang_list';
import auth from '@/auth';
import wdm16 from '@/wdm16';
import store from '@/store';
import { mapGetters } from 'vuex';
export default {
  name: 'login',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreUser: 'StoreUser',
    StoreLoading: 'StoreLoading',
    StoreModal: 'StoreModal',
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreMenuData: 'StoreMenuData',
    StoreCurrencyType: 'StoreCurrencyType',
    StoreCurrencyTypeList: 'StoreCurrencyTypeList',
    StoreFrontendWebsiteunit: 'StoreFrontendWebsiteunit',
    StoreLangList: 'StoreLangList',
    StoreShopCartList: 'StoreShopCartList',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
  }),
  props: {
    p_viewMode: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      d_resetMailButtonClicked: false,
      d_captchaUse: false,
      d_captchaCode: '',
      d_captchaWrittenCode: '',
      d_accessKeyToken: '',
      d_userExternalapplicationQuery: '',
      d_userExternalapplicationClientID: '',
      d_userExternalapplicationData: '',
      d_resetData: {'mail': ''},
      d_showPasswordReset: false,
      d_usernamePasswordError: false,
      d_selectedLang: 'tr',
      wdm16: wdm16.wdm16,
      credentials: {
        username: '',
        password: ''
      },
      error: '',
      store: store,
      d_showLoginButtonClicked: false
    };
  },
  created: function () {
    // console.log('this.$route : ', this.$route);
    if (this.p_viewMode !== 'only_show') {
      if (this.$route.query.access_key_token && this.$route.query.external_application) {
        this.d_accessKeyToken = this.$route.query.access_key_token;
        this.d_userExternalapplicationClientID = this.$route.query.external_application;
        this.f_loginStart();
      } else if (this.$route.query.external_application && this.$route.query.external_query) {
        this.d_userExternalapplicationQuery = this.$route.query.external_query;
        // console.log('this.d_userExternalapplicationQuery : ', this.d_userExternalapplicationQuery);
        this.d_userExternalapplicationClientID = this.$route.query.external_application;
        this.f_userExternalapplicationGet();
        this.d_captchaUse = true;
      } else {
        if (this.StoreFrontendSettings.data.login_function_external === '1') {
          // login ekranından uzaklaşmamak ve account sayfasına gitmemesi için test süreçlerine alttaki satır kapatılabilir.
          this.f_waccountLogin();
        } else {
          this.d_captchaUse = true;
        }
      }
    }
  },
  mounted: function () {
    // console.log('this.StoreLangTranslation : ', this.StoreLangTranslation);
    // console.log('this.StoreLang : ', this.StoreLang);
    // frontend ayarları yapılırken this.p_viewMode değeri only_show olarak gönderiliyor. Böylece logout otomatik tetiklenmiyor.
    if (this.p_viewMode !== 'only_show') {
      auth.logout();
    }
    if (this.d_captchaUse) {
      this.f_createCaptcha();
    }
  },
  methods: {
    f_calculateLoginGeneralStyle: function () {
      let style = '';
      let image = '';
      // let image = 'https://cdn.wisdomera.io/images/wisdomera_login_background.png';
      // let image = 'https://cdn.wisdomera.io/evideep/4134.jpg';
      if (this.StoreFrontendSettings.data.login_background_image) {
        image = this.StoreFrontendSettings.data.login_background_image;
      }
      if (image) {
        style = 'background-image: url(' + image + '); background-size: cover; background-repeat: no-repeat;';
      } else {
        let login_background_color = 'white';
        if (this.StoreFrontendSettings.data.login_background_color) {
          login_background_color = this.StoreFrontendSettings.data.login_background_color;
        }
        style += 'background-color: ' + login_background_color + ';';
      }
      return style;
    },
    f_calculateSloganTextStyle: function () {
      let style = 'text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_calculatePasswordResetStyle: function () {
      let style = 'cursor: pointer;';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_calculateRegisterButtonStyle: function () {
      let style = 'cursor: pointer;';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_calculateGotoDashboardStyle: function () {
      let style = 'cursor: pointer;';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_calculateWaccountLoginStyle: function () {
      let style = 'cursor: pointer;';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_calculateLoginApplicationNameStyle: function () {
      let style = '';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_createCaptcha: function() {
      this.d_captchaWrittenCode = '';
      this.d_captchaCode = '';
      //clear the contents of captcha div first 
      document.getElementById('captcha').innerHTML = "";
      let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
      let lengthOtp = 6;
      let captcha = [];
      for (let i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      let canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 100;
      canv.height = 50;
      let ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha.join(""), 0, 30);
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.d_captchaCode = captcha.join("");
      // console.log('this.d_captchaCode : ', this.d_captchaCode);
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
      this.$forceUpdate();
    },
    f_userExternalapplicationGet: function () {
      // account login uygulamasında bu tetikleniyor. Böylece hangi external uygulama için login olunacağı datası getiriliyor.
      let query = 'user_externalapplication_client_id=' + this.d_userExternalapplicationClientID;
      query += '&data_type=wdmr_mode';
      let data = {};
      UserExternalapplicationService.user_externalapplication_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userExternalapplicationData = resp.data.data;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_sendResetMail: function () {
      this.d_resetMailButtonClicked = true;
      let query = 'mail=' + this.d_resetData.mail;
      let data = {};
      LoginService.password_reset_by_mail(query, data)
        .then(resp => {
          this.d_resetMailButtonClicked = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.f_goToMainPage();
            alert(this.StoreLangTranslation.data['register_done_please_mail_approve_check'][this.StoreLang]);
          } else {
            alert(resp.data.status_code + ' / ' + resp.data.status_message);
          }
        });
    },
    f_mailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.d_resetData[param] = value.toLowerCase()
    },
    f_passwordReset: function () {
      if (this.d_showPasswordReset) {
        this.d_showPasswordReset = false;
      } else {
        this.d_showPasswordReset = true;
      }
    },
    f_selectLang: function (lang) {
      this.d_selectedLang = lang.label;
      localStorage.setItem('lang', this.d_selectedLang);
      this.$store.commit('MutationLang', this.d_selectedLang);
      localStorage.setItem('active_lang', JSON.stringify(lang));
    },
    f_goToRegisterPage: function () {
      this.$router.push({ path: '/register', query: {} });
    },
    f_goToMainPage: function () {
      let redirect_dashboard_path = this.f_getRedirectDashboardRoute();
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      this.$router.push({ path: '/' + redirect_dashboard_path, query: {} });
    },
    f_waccountLogin: function () {
      /*
        console.log(window);
        console.log(window.location.href);
      */
      // http://localhost:9001/#/login?external_application=8d9fde8c-3538-4516-b1ad-9e3b66c693dd&external_query=http://localhost:9001%2F%23%2Flogin
      let url = 'https://account.wisdomera.io/#/login?external_application=' + this.StoreFrontendSettings.data.waccount_external_key;
      url += '&external_query=' + window.location.href.replace('/', '%2F').replace('?', '%3F').replace('#', '%23');
      window.open(url, '_self');
    },
    f_getRedirectDashboardRoute: function () {
      let redirect_dashboard_path = '';
      for (let lang_index in lang_list) {
        let lang_label = lang_list[lang_index]['label'];
        for (let i in this.StoreMenuData.data.list) {
          let is_dashboard = '';
          try {
            is_dashboard = this.StoreMenuData.data.list[i].is_dashboard['value'];
          } catch (err) {}
          if (is_dashboard === '1') {
            let route_path = '';
            try {
              route_path = this.StoreMenuData.data.list[i].route_path[lang_label];
            } catch (err) {}
            if (route_path) {
              redirect_dashboard_path = route_path;
              break;
            }
          }
          if (this.StoreMenuData.data.list[i].list && this.StoreMenuData.data.list[i].list.length > 0) {
            for (let sub_i in this.StoreMenuData.data.list[i].list) {
              let is_dashboard = '';
              try {
                is_dashboard = this.StoreMenuData.data.list[i].list[sub_i].is_dashboard['value'];
              } catch (err) {}
              if (is_dashboard === '1') {
                let route_path = '';
                try {
                  route_path = this.StoreMenuData.data.list[i].list[sub_i].route_path[lang_label];
                } catch (err) {}
                if (route_path) {
                  redirect_dashboard_path = route_path;
                  break;
                }
              }

            }
          }
        }
      }
      // console.log('this.StoreMenuData ', this.StoreMenuData);
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      return redirect_dashboard_path;
    },
    f_styleLoginBackground: function () {
      let style = '';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginCardOut: function () {
      let style = '';
      style += 'margin-top: 50px;';
      style += 'border-radius: 20px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_out_background_color;
      } catch (err) {}

      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_out_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 5px 5px 10px ' + y + ';';
      // console.log('style : ', style);
      style += 'opacity: 0.9;';
      return style;
    },
    f_styleLoginCardIn: function () {
      let style = '';
      style += 'margin-top: 0px;';
      style += 'border-radius: 15px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_in_background_color;
      } catch (err) {}
      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_in_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 2px 2px 3px ' + y + ';';
      style += 'opacity: 0.9;';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginButton: function () {
      let style = '';
      style += 'margin: 2px;';
      style += 'width: 100px;';
      style += 'color: white;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_buttons_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'border-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginInput: function () {
      let style = '';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_inputs_border_color;
      } catch (err) {}

      style += 'border: 1px solid ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginLoading: function () {
      let style = '';
      style += 'margin: 2px;';
      style += 'width: 100px;';
      style += 'color: white;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_loading_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'border-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    toLowerCase (value, event) {
      return value.toLowerCase()
    },
    /*
      f_startLogin: function () {
        this.d_showLoginButtonClicked = true;
        setTimeout(function () {
          this.d_showLoginButtonClicked = false;
        }.bind(this), 5000)
      },
    */
    f_goToResetPassword: function () {
      this.$router.push('/resetpassword');
    },
    f_loginStart: function () {
      let redirect_url = '';
      if (this.d_userExternalapplicationData) {
        // Account login uygulamasında (waccount) d_userExternalapplicationData data dolu oluyor.
        // Böylece hangi external uygulama için login olunacağı datası getiriliyor.
      } else {
        redirect_url = '/';
        let router_query = this.$router.currentRoute.query;
        if (router_query.new_url) {
          redirect_url = router_query.new_url;
          for (let query in router_query) {
            if (query !== 'new_url') {
              redirect_url += '&' + query + '=' + router_query[query];
            }
          }
        }
      }
      if (this.d_captchaCode === this.d_captchaWrittenCode) {
        this.d_usernamePasswordError = false;
        this.d_showLoginButtonClicked = true;
        let credentials = {
          'username': this.credentials.username,
          'password': this.credentials.password,
          'multi_account_password': 0,
          'not_control_password': 0,
          'externalapplication_key': this.d_userExternalapplicationClientID,
          'access_key_token': this.d_accessKeyToken,
        };
        auth.login(this, credentials, redirect_url)
          .then(resp => {
            // console.log(resp);
            // external login kavramı aktif olduğunda aşağıdaki if ve else if satırları çalışmaktadır.
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (this.d_accessKeyToken) {
                this.d_showLoginButtonClicked = false;
              } else if (this.d_userExternalapplicationData) {
                let query = 'externalapplication_key=' + this.d_userExternalapplicationClientID;
                let data = {};
                LoginService.user_accesskey_record(query, data)
                  .then(resp => {
                    if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                      let query_2 = 'externalapplication_key=' + this.d_userExternalapplicationClientID;
                      let data_2 = {};
                      LoginService.user_accesskey_token_create(query_2, data_2)
                        .then(resp => {
                          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                            this.d_showLoginButtonClicked = false;
                            let access_key_token = resp.data['access_key_token'];
                            // let access_key = resp.data['access_key'];
                            // window.open(url, '_blank');
                            let url = this.d_userExternalapplicationQuery;
                            if (this.d_userExternalapplicationQuery.indexOf('?') !== -1) {
                              url += '&access_key_token=' + access_key_token;
                              // url += '&access_key=' + access_key;
                              url += '&external_application=' + this.d_userExternalapplicationClientID;
                            } else {
                              url += '?access_key_token=' + access_key_token;
                              // url += '&access_key=' + access_key;                            
                              url += '&external_application=' + this.d_userExternalapplicationClientID;
                            }
                            // window.open(url, '_blank');
                            window.open(url, '_self');
                          } else {
                            alert(resp.data.status_code + ' / ' + resp.data.status_message);
                          }
                        });
                    } else {
                      alert(resp.data.status_code + ' / ' + resp.data.status_message);
                    }
                  });
              }
            } else {
              alert(resp.data.status_message);
              this.d_usernamePasswordError = true;
              this.d_showLoginButtonClicked = false;
            }
          }, resp => {
            this.d_showLoginButtonClicked = false;
          });
        /*
          if (!this.d_showLoginButtonClicked) {
            this.d_showLoginButtonClicked = true;
            // this.f_startLogin();
            var credentials = {
              username: this.credentials.username,
              password: this.credentials.password,
              multi_account_password: 0,
              not_control_password: 0
            };
            auth.login(this, credentials, '/');
          }
        */
      } else {
        this.f_createCaptcha();
        alert(this.StoreLangTranslation.data['captcha_code_incorrect'][this.StoreLang]);
      }
    },
  },
  watch: {
    /*
      'StoreLoading.status': function () {
        this.d_showLoginButtonClicked = this.StoreLoading.status;
      }
    */
  }
}

</script>

<style type="text/css">
canvas {
  /*background-color: #000;*/
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
</style>

